@import '../styles/base.scss';

html,
body,
#__next {
  height: 100vh;
  padding: 0;
  margin: 0 auto;
  user-select: none;
}

#__next {
  padding: 0 2em;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: black;
  background: linear-gradient(132deg, black, #1b222c);
  background-size: 2400% 2400%;
  animation: Gradient 25s ease-in-out infinite;
  color: white;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Oswald', sans-serif;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.spinnerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
}
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: $color-yellow;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
